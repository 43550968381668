import { ThemeType } from "@hex/common";

import { useQueryParams } from "./routes";

// `isEmbeddedUrl` can be used if `queryParams` is already available.
// `useIsEmbeddedUrl` is a convenience hook which also gets the query params for you.
export function isEmbeddedUrl(queryParams: URLSearchParams): boolean {
  return queryParams.get("embedded") === "true";
}
export function useIsEmbeddedUrl(): boolean {
  const queryParams = useQueryParams();
  return isEmbeddedUrl(queryParams);
}
export function isSignedEmbeddingApp(queryParams: URLSearchParams): boolean {
  return isEmbeddedUrl(queryParams) && queryParams.get("embeddedToken") != null;
}
export function useIsSignedEmbeddingApp(): boolean {
  const queryParams = useQueryParams();
  return isSignedEmbeddingApp(queryParams);
}

export function useIsNotionEmbeddedUrl(): boolean {
  const queryParams = useQueryParams();
  return queryParams.get("embed_notion") === "true";
}

export function useIsNoEmbedFooterUrl(): boolean {
  const queryParams = useQueryParams();
  return queryParams.get("noEmbedFooter") === "true";
}

export function useIsNoEmbedOutlineUrl(): boolean {
  const queryParams = useQueryParams();
  return queryParams.get("noEmbedOutline") === "true";
}

export function useIsScreenshotUrl(): boolean {
  const queryParams = useQueryParams();
  return queryParams.get("screenshot") === "true";
}

export function useThemeOverride(): ThemeType | null {
  const queryParams = useQueryParams();
  const themeParam = queryParams.get("theme");
  return themeParam != null ? (themeParam.toUpperCase() as ThemeType) : null;
}
